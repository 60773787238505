<template>
  <div>
    <el-col
      :xl="{span: 12,offset:6}"
      :lg="{span: 12,offset:6}"
      :md="{span: 12,offset:6}"
      :sm="{span: 24}"
      :xs="{span: 24}"
    >
      <el-form-item
        :prop="prop"
        :rules="rules"
        :error="errors"
      >
        <el-checkbox
          v-model="instantor_data.open_banking"
          @change="handleChange"
        >
          <div>
            {{ label ? label : $t('commons.open_banking.label') }}
          </div>
        </el-checkbox>
      </el-form-item>
    </el-col>
    <el-col :span="24">
      <el-row class="row justify-content-center pz-margin-bottom-10">
        <pz-svg-icon
          class-name="logo2"
          icon-class="instantor"
          @click="handleChange(true)"
        />
      </el-row>
      <el-row class="row justify-content-center">
        <el-col :span="24">
          <slot name="clarifyingText">
            <i18n
              path="commons.open_banking.text"
              tag="p"
            >
              <a
                place="instanor_link"
                href="https://www.instantor.com/"
                target="_blank"
              > Instantor AB</a>
              <b
                place="company"
                class="pz-color-primary"
              >{{ (company || alias)|capitalize({onlyFirstLetter: false}) }}</b>
            </i18n>
          </slot>

          <el-col :span="24">
            <pz-svg-icon
              icon-class="secure"
              class="secure-icon big"
            />
            <span style="vertical-align: top"> {{ $t('commons.open_banking.not_keep_pasword') }}</span>
            <br>
          </el-col>
        </el-col>
      </el-row>

      <el-dialog
        class="pz-dialog-banking"
        :title="$t('commons.open_banking.title_header')|capitalize({ onlyFirstLetter: true })"
        :visible.sync="centerDialogVisible"
        center
      >
        <el-row
          v-if="open_first"
          class="row justify-content-center"
        >
          <pz-title>
            <span
              slot="title"
              class="pz-font-size-md"
            >{{ $t('commons.open_banking.title') }}</span>
          </pz-title>
          <el-row>
            <ul class="statement-list">
              <li
                v-for="(statement,index) in statements"
                :key="index"
              >
                <pz-svg-icon
                  class-name="pz-font-size-md  pz-color-info li-icons"
                  :icon-class="statement.icon"
                />
                {{ statement.label }}
              </li>
            </ul>
          </el-row>
          <el-col
            class="row justify-content-center"
          >
            <pz-svg-icon
              class-name="logo"
              icon-class="instantor"
            />
          </el-col>
          <el-col
            class="row justify-content-center"
            :span="24"
          >
            <pz-button
              type="primary"
              :button-name="$t('continue')"
              @click="handleClick()"
            />
          </el-col>
        </el-row>

        <pz-instantor
          v-else
          v-model="instantor_data"
          :credentials="credentials"
          @close="closeDialog()"
        />
      </el-dialog>
    </el-col>
  </div>
</template>

<script>
import PzInstantor from "@/commons/forms/Fields/OpenBanking/PzInstantor";
import {getInstantorSettings} from "@/routes/api/resources";
import {mapGetters} from "vuex";
import PzTitle from "@/commons/titles/PzTitle";
import PzButton from "@/commons/forms/Buttons/PzButton";
import {isEmpty} from "@/commons/utils/functions";

export default {
  name: "PzOpenBanking",
  components: {PzButton, PzTitle, PzInstantor},

  props: {
    errors: {type: String, default: null},
    rules: {type: Object, default: null},
    prop: {type: String, default: null},
    value: {type: Object, default: null},
    label: {type: String, default: null},
  },
  data() {
    return {
      statements: [
        {icon: 'locked', label: this.$t('commons.open_banking.statements.acces_data')},
        {icon: 'circle-check', label: this.$t('commons.open_banking.statements.service')},
        {icon: 'secure', label: this.$t('commons.open_banking.statements.save')},
        {icon: 'plain', label: this.$t('commons.open_banking.statements.fast')},
        {icon: 'star', label: this.$t('commons.open_banking.statements.easy')},
      ],
      open_first: true,
      credentials: {},
      centerDialogVisible: false,
      instantor_data: {
        open_banking: false,
        pased: false,
      },
    }
  },
  computed: {
    ...mapGetters(['company', 'alias'])
  },
  watch: {
    value(value) {
      this.setValue(value)
    },
    instantor_data(value) {
      this.emitData(value)
    },

  },
  created() {
    this.fetchData();
  },
  mounted() {
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    handleChange(val) {
      this.openDialog(true);
      this.instantor_data.open_banking = this.instantor_data.pased
      this.emitData(this.instantor_data);
    },
    closeDialog() {
      this.openDialog(false);
      this.open_first = true;
      this.instantor_data.open_banking = this.instantor_data.pased
      this.emitData(this.instantor_data);
    },

    setValue(value) {
      const input = this.instantor_data;
      if (isEmpty(input)) {
        this.instantor_data = {open_banking: false, pased: false};
      }
      if (input === value) return;
      this.instantor_data = value;
      this.emitData(this.instantor_data);
    },
    openDialog(open) {
      this.centerDialogVisible = open;
    },
    emitData(data) {
      this.instantor_data = data;
      this.instantor_data.open_banking = data.pased;
      this.$emit('input', data);
    },
    handleClick() {
      this.open_first = false;
    },
    fetchData() {
      getInstantorSettings().then((res) => {
        this.credentials = res.data;
      });
    }
  },
}

</script>

<style lang="scss" scoped>
.statement-list {
  list-style: none;
}

.secure-icon {
  color: #45a245;

  &.big {
    font-size: 35px;
  }
}

.li-icons {
  margin-right: 10px;
}

.logo {
  width: 230px !important;
  height: 50px !important;
  color: #005152;
}

.logo2 {
  width: 200px !important;
  height: 40px !important;
  color: #005152;

}

.logo2:hover {
  cursor: pointer;

}

</style>