<template>
  <div
    v-loading="loading"
    class="pz-instantor"
  >
    <div
      id="itor"
    />
  </div>
</template>

<script>
export default {
    name: "PzInstantor",
    components: {},
    props: {
        credentials: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            loading: true,
            data: null,
        }
    },
    watch: {
        credentials(value) {
            this.setInstantor(value);
        }
    },
    created() {
    },
    mounted() {
        this.setInstantor(this.credentials)
    },
		update() {
       console.log('update');
    },
    methods: {
        setInstantor(credencials) {
            let instantor = new window.Instantor(credencials.instance);
            Object.entries(credencials.credentials).forEach(([key, value]) => {
                instantor.userParam(key, value);
            });
            instantor.load('#itor');
            let self = this;
            instantor.attachEventListener('load', function () {
                self.loading = false;
            });
            instantor.attachEventListener("chooseBank", function (data) {
                data['identifier'] = instantor.get_request_identifier();
                self.data = data;
                self.data['pased'] = false;
            });
            instantor.listener(function (response) {
                console.log(response)
                switch (response) {
                    case 'process-finished':
                        self.$emit('close');
                        self.data['pased'] = true;
                        self.$emit('input', self.data);
                        break;
                    case 'invalid-login':
                        break;
                    default:
                        /* Process encountered an error. */
                        break;
                }
            });
        }
    }
}

</script>
